import logo from './logo.png';
import './App.css';
import Confetti from 'react-confetti'

function App() {
  return (
    <div className="App">
        <header className="App-header">     
         <Confetti gravity={0.03} wind={0.005} width={window.screen.width} height={window.screen.height}/>
          <img src={logo} className="App-logo" alt="logo" />
          <p>Coming soon!</p>
          <a
            className="App-link"
            href="https://www.instagram.com/rebecca_taillieu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
          </a>
        </header>
    </div>
  );
}

export default App;
